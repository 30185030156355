import React, { useEffect } from 'react'
import { Typography, Grid, TextField, InputAdornment, Button} from '@material-ui/core'
import LoginIcon from '@material-ui/icons/Person'
import PassIcon from '@material-ui/icons/VpnKey'

import 'bootstrap/dist/css/bootstrap.min.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Modallogin from './ModalLogin'
import Api from '../../providers/Api/Api'
import GeradorLogGenerico from '../../providers/GeradorLogGenerico';
import LogoPrinterconnect from './imagens/logoPrinterConnect.png'
import {Alert, AlertTitle} from '@material-ui/lab'
import Swal from 'sweetalert2'
import {withRouter} from 'react-router-dom'


const NavPrincipal = function(props){
    const [login, setLogin] = React.useState('')
    const [senha, setSenha] = React.useState('')


    useEffect(()=>{
console.log(props)
    }, [])

   

    const autenticarUsuario = () =>{
        /*props.history.push({pathname:'/home/'+props.dadosEmpresa.empresa, state :{
            dadosEmpresa : []//response.data.data
        }})*/
        const url = new URL(window.location.href);

        console.log("Codigo da empresa selecionada " + props.dadosTemplateEmpresa.TB01007_CODIGO)

        console.log('URL Empresa ' + url.host)

        var codigoEmpresaTemp = '00';

        if(url.host === 'uziprint.com' || url.host === 'www.uziprint.com'){
            codigoEmpresaTemp = '03'
        }else{
            codigoEmpresaTemp = '00';
        }

        props.setLoading(true)
        Api.post('ClientesController/autenticarUsuario', {
            login : login, 
            senha: senha, 
            codigoEmpresa  :props.dadosTemplateEmpresa.TB01007_CODIGO ? props.dadosTemplateEmpresa.TB01007_CODIGO : codigoEmpresaTemp,
        }).then(response=>{
        
            if(response.status == 200 && response.data.status =='OK'){
                var retorno = JSON.stringify(response.data.payload)
                sessionStorage.setItem('corporate@usuario', retorno);
                
                if( response.data.payload.TB01008_TOKEN ){
                    props.history.push({pathname:'/home/empresa', state :{
                        dadosEmpresa : response.data.payload, 
                        printerconnect : props.dadosTemplateEmpresa.TB01007_TOKEN, 
                        codigoEmpresa  :props.dadosTemplateEmpresa.TB01007_CODIGO ? props.dadosTemplateEmpresa.TB01007_CODIGO : codigoEmpresaTemp, 
                        logotipo : props.dominioEmpresa,
                        url : props.url, 
                        
                    }})
                    
                }else{
                    props.history.push({pathname:'/equipamentos/empresa', state :{
                        dadosEmpresa : response.data.payload, 
                        printerconnect : props.dadosTemplateEmpresa.TB01007_TOKEN, 
                        codigoEmpresa  :props.dadosTemplateEmpresa.TB01007_CODIGO ? props.dadosTemplateEmpresa.TB01007_CODIGO : codigoEmpresaTemp,  
                        logotipo : props.dominioEmpresa,
                        url : props.url, 
                    }})
                    
                }
                
            }else{
                GeradorLogGenerico(response.data.mensagem ? 'Login: '+login+' '+response.data.mensagem : 'Login: '+login);
                sessionStorage.removeItem('corporate@usuario');
                Swal.fire({
                    title :'Corporate WEB', 
                    text : 'Não foi possível autenticar! Verifique usuário e senha!', 
                    showConfirmButton:true, 
                    confirmButtonText:'Ok', 
                    showCancelButton:false, 
                    icon:'warning'
                })
            }
            props.setLoading(false)
        }).catch(error=>{
            GeradorLogGenerico(error);
            
            Swal.fire({
                title :'Corporate WEB',  
                text : 'Não foi possível autenticar! Verifique usuário e senha!' +error , 
                showConfirmButton:true, 
                confirmButtonText:'Ok', 
                showCancelButton:false, 
                icon:'error'
            })
            props.setLoading(false)
        })

    }
    return <React.Fragment>
         <div id='nav-principal-main'>
         
        <div id='nav-brand'>{props.icone}</div>
        <div id='nav-menu'>          
                <span>Outsourcing</span>
                <span>Térmicas</span>
                <span>Gestão de Documentos</span>
                <span>Impressão 3D</span>   
                <a href ={props.dominioEmpresa == 'CENTERMAQ' 
                    ? 'https://www.datacount.com.br/downloads/v2/centermaq-collector-setup.zip' 
                    : 'https://www.datacount.com.br/downloads/v2/uziprint-collector-setup.zip'} 
                target='_blank'>
                    <button className='btn btn-primary'>Download DCA</button>  
                </a>  
        </div>
        {
            /*
            https://www.printerconnect.com.br/downloads/printerconnec-app-setup.zip
            */
        }
        <div id='nav-social'>
            <Grid container spacing={2} full>
                <Grid item xs = {6}>
                    <TextField className='input-login' s  fullWidth value={login} onChange={(e)=>setLogin(e.target.value)}  InputProps={{
                        startAdornment : <InputAdornment position='start'><LoginIcon style= {{color:'#444'}}/></InputAdornment>
                    }}/>
                </Grid>
                <Grid item xs = {4}>
                    <TextField className='input-login' fullWidth type='password'  value={senha} onChange={(e)=>setSenha(e.target.value)} InputProps={{
                        startAdornment : <InputAdornment position='start'><PassIcon  style= {{color:'#444'}}/></InputAdornment>
                    }} onKeyDown={(e)=>{
                        if(e.keyCode == 13){
                            if(login == '' || senha == ''){
                                Swal.fire({
                                    title : 'Corporate Web', 
                                    text : 'Informe o usuário e senha antes de continuar!', 
                                    icon : 'error', 
                                    showConfirmButton : true, 
                                    showCancelButton : false,                             
                                })
                                return;
                            }
                            autenticarUsuario()
                        }
                    }}/>
                </Grid>

                <Grid item xs = {2}>
                <Button type='submit' id='btnEntrar'   color="secondary" fullWidth variant='contained' onClick={()=>{
                    if(login == '' || senha == ''){
                        Swal.fire({
                            title : 'Corporate Web', 
                            text : 'Informe o usuário e senha antes de continuar!', 
                            icon : 'error', 
                            showConfirmButton : true, 
                            showCancelButton : false,                             
                        })
                        return;
                    }
                    autenticarUsuario()
                }}>Entrar</Button>
                </Grid>
            </Grid>
        </div>
        
    
    </div>
    <div id='msg-msg-problemas'>
    {/*<Alert severity="error">
        <AlertTitle>Aviso Importante</AlertTitle>
   
        <span>Estamos passando por algumas instabilidades, caso esteja enfrentando dificuldades de acesso ao portal, por favor nos informe através do endereço <strong>gestao.marilia@centermaq.com</strong></span>
        <br/>
        <span><strong>Para forçar uma atualização do site, pressione as teclas CTRL+F5</strong></span>
            </Alert>*/}
    </div>
</React.Fragment>
}


export default withRouter(NavPrincipal);

