

var axios = require('axios');
const url = new URL(window.location.href);
module.exports = axios.create({
  baseURL: 'http://centermaq.net/api/index.php/',
  timeout: 25000, 
  eaders: {
    'Content-Type': 'application/json', 
    'Authorization': `Bearer ${url.searchParams.get('token')}`,
  },
  // Methods you intend to use (for documentation)
  // Note: This property is not used by Axios directly
   //methods: ['get', 'post', 'put', 'delete', 'options'], // List of methods
  
  // Note: This property is not used by Axios directly
   //origin: '*'
});


//const url = new URL(window.location.href);
/*
var axios = require('axios');
const url = new URL(window.location.href);
module.exports = axios.create({
  baseURL:'http://'+url.host+'/api/index.php/',
  timeout: 25000, 
});
*/
/*
var axios = require('axios');
const url = new URL(window.location.href);
module.exports = axios.create({
  baseURL:'http://centermaq.net/api/index.php/',
  timeout: 25000, 
});
*/


