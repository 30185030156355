import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetalhesIcon from '@material-ui/icons/FormatListBulleted'
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Divider, Typography , Fab, Badge, InputAdornment, CircularProgress} from '@material-ui/core';
import DetalhesColeta from './Printerconnect/GridDisplayToner'
import Swal from 'sweetalert2';
import NivelToner from './Printerconnect/NivelToner'
import Build from '@material-ui/icons/Build'
import Cart from '@material-ui/icons/AddShoppingCart'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import {Alert, AlertTitle} from '@material-ui/lab'
import Dataservice from '../../providers/Api/Dataservice';
import Conexao from '../../providers/Api/Api'
import InkIcon from '@material-ui/icons/InvertColors'
import {withRouter} from 'react-router-dom'
import ModalVideo from '../../components/base/ModalVideo';
import PrinterconnectV1 from '../../providers/Api/PrinterconnectV1Api'
import GeradorLogErro from '../../providers/GeradorLogErro'
import somenteTexto from '../../providers/somenteTexto';
import somenteNumeros from '../../providers/somenteNumeros';


const  ModalPedidoSuprimentoV2 = (props) =>{
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [coleta, setColeta] = React.useState([]);
  const [contadorMono, setContadorMono] = React.useState('0');
  const [contadorColor, setContadorColor] = React.useState('0');
  const [contadorColorido, setContadorColorido] = React.useState('0');
  const [contadorMonocromatico, setContadorMonocromatico] = React.useState('0');
  const [solicitante, setSolicitante]= React.useState('');
  const [emailSolicitante, setEmailSolicitante]= React.useState('');
  const [observacoes, setObservacoes]= React.useState('');
  const [submitForm, setSubmitForm] = React.useState(false);
  const [statusPedido, setStatusPedido] = React.useState('Toner Reserva');
  const [equipamentoDatabit, setEquipamentoDatabit]= React.useState([]);
  const [situacao, setSituacao] = React.useState('Equipamento Normal');
  const [retirarUsados, setRetirarUsados]= React.useState('Não');
  const {CMQ00001_CODEMPRESA, CMQ00001_OPERACAO, CMQ00001_TIPODESC, CMQ00001_VEND, CMQ00001_STATUS, CMQ00001_CODTEC, CMQ00001_CONDPAG } = props.dadosEmpresa.operacaoEmpresa
  const equipamento = props.equipamento
  const [ciano, setCiano]= React.useState(0);
  const [magenta, setMagenta]= React.useState(0);
  const [amarelo, setAmarelo]= React.useState(0);
  const [preto, setPreto]= React.useState(0);
  const [copiaEmail, setCopiaEmail] = React.useState('');
  const [contatos, setContatos] = React.useState('');
  const [travarSolicitante, setTravarSolicitante] = React.useState(false);



  const refMono = React.useRef();
  const refColor = React.useRef();

  React.useEffect(()=>{
    if(coleta && coleta.numeroserie){
        setContadorMonocromatico(coleta.monototal ? coleta.monototal : 0)
        setContadorColorido(coleta.colortotal ? coleta.colortotal : 0)
    }
  },[coleta])

  const handleClickOpen = () => {
    setContadorMonocromatico(equipamento.CONTADORMONO ? equipamento.CONTADORMONO  : 0)
    setContadorColorido(equipamento.CONTADORCOLOR ? equipamento.CONTADORCOLOR : 0)
    getContatosNotificacao()
    getDetalhesColetaV1();
    setCopiaEmail(equipamento.CMQ00001_EMPRESA.toUpperCase() == 'UZIPRINT' ? 'suprimento@uziprint.com, andre@centermaq.com, claudenir@centermaq.com' : 'suprimento@centermaq.com, andre@centermaq.com, claudenir@centermaq.com')
   
    setCiano(0)
    setMagenta(0)
    setAmarelo(0)
    setPreto(0)
    setObservacoes('')
    setColeta([])      
    setOpen(true)
    getHistoricoPedido()
    
    if(props.gerarLogSite){
        props.gerarLogSite('[EQT]->[MDPED]->'+equipamento.TB02112_NUMSERIE);
    }
   // getEquipamentoDatabit();
  };

  const handleClose = () => {
    if(props.setLoading){

      props.setLoading(false)
 
     }
    setOpen(false);
  };

  

  const getHistoricoPedido = () =>{
      props.Conexao.post('tonerControllerV2?empresa='+props.empresa, {
          codigoCliente : props.dadosEmpresa.CODIGOCLIENTE, 
          numeroSerie:equipamento.NUMEROSERIE
      }).then(response=>{

      }).catch(error=>{

      });
  }


  const getContatosNotificacao = () =>{
    setLoading(true)
    Conexao.post('clientesController/getContatosNotificacao?codigoEmpresa=', {
        codigoCliente : equipamento.TB02111_CODCLI, 
        codigoEmpresa : equipamento.TB02176_CODEMP, 
        codigoContrato : equipamento.TB02112_CODIGO, 
        codigoSite : equipamento.TB02112_CODSITE, 
        codigoDepartamento : equipamento.TB02112_CODDEPTO, 
        filtrar : 't',
        tipoNotificacao :'toner'
       
    }).then(response=>{
        
        if(response.data.status =='OK'){
            
            setContatos(response.data.payload)
      
            
        }else{
            setContatos([])
           
        }
        setLoading(false)
    }).catch(error=>{
        setContatos('')

        setLoading(false)
    })
}



 

const insertPedidoSuprimentoDatabit = () =>{

    if(props.setLoading){

       // props.setLoading(true)

    }else{

       // setLoading(true)

    }

    let contMono = contadorMonocromatico;

    let contColor = contadorColorido;

    let contTotal = parseInt(contMono) + parseInt(contColor);

    const isoStr = new Date().toISOString()

    let totalPreto = " Quantidade Preto: " + preto ? preto : 0; 

    let totalCiano = " Quantidade Ciano: " + ciano ? ciano : 0; 

    let totalMagenta = "Quantidade Magenta: " + magenta ? magenta : 0; 

    let totalAmarelo = "Quantidade Amarelo: " + amarelo ? amarelo : 0; 

    const emailNotificacao =((contatos != '' ? contatos :  equipamento.TB02176_EMAIL)  + (emailSolicitante != '' ? ','+emailSolicitante : '')) ;

    const textoObservacoes  = somenteTexto(observacoes);

    const parametros = {
            codemp : equipamento.TB02176_CODEMP, 
            codcli : equipamento.TB02176_CODCLIINT ? equipamento.TB02176_CODCLIINT : props.dadosEmpresa.TB01066_CODCLI,
            numserie : equipamento.TB02112_NUMSERIE,  
            codsite :equipamento.TB02112_CODSITE != '' ? equipamento.TB02112_CODSITE : null, 
            codtec : equipamento.CMQ00001_CODTEC ? equipamento.CMQ00001_CODTEC.padStart(4, '0') : '0000',
            operacao : 1, 
            conttotal : contTotal, 
            contpb : contMono, 
            contcolor: contColor, 
            condpag :  equipamento.CMQ00001_CONDPAG ? equipamento.CMQ00001_CONDPAG.padStart(3, '0') : '000',
            coddepto : equipamento.TB02112_CODDEPTO ? equipamento.TB02112_CODDEPTO : null, 
            contrato : equipamento.TB02112_CODIGO, 
            data : isoStr, 
            dataexec : isoStr, 
            dtcad : isoStr,
            nome : somenteTexto(solicitante), 
            fone : equipamento.TB02176_FONE ? equipamento.TB02176_FONE.substr(0,11) : null, 
            email : emailNotificacao.substr(0, 200) , 
            obs : 'Preto: ' + totalPreto+', Ciano: '+
                            totalCiano+', Magenta: '+
                            totalMagenta+', Amarelo: '+
                            totalAmarelo+' - Situação: ' + 
                            situacao+', Status Pedido: '+
                            statusPedido+', Retirar Usados: '+
                            retirarUsados+', Observações: ' + 
                            textoObservacoes,                     
            vend : equipamento.CMQ00001_VEND ? equipamento.CMQ00001_VEND : '0000' ,
            status : equipamento.CMQ00001_STATUS ? equipamento.CMQ00001_STATUS : CMQ00001_STATUS, 
            tipodesc :equipamento.TB02176_TIPODESC ? equipamento.TB02176_TIPODESC : equipamento.CMQ00001_TIPODESC.padStart(2, '0') , 
            situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A',         
            tipofrete : equipamento.CMQ00001_TIPOFRETE ? equipamento.CMQ00001_TIPOFRETE : '0', 
            vlrnota : 0, 
            qtde : 0
    }
    
    if(!parametros.codsite ){
        parametros.codsite = null;  
    }

    if(!parametros.coddepto){
        parametros.coddepto = null;  
    }
    
    if(!parametros.codcli){
        parametros.codcli = props.dadosEmpresa.TB01066_CODCLI;
    }

    
    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[INCLUIR]', '');

    Dataservice.post('RequisicaoController/incluir', {
            ...parametros,                       
    }).then(response=>{
        if(response.status == 200 && response.data.status.code == '1'){

            GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[SUCCESS]', '');

            Conexao.post('pedidosController/insertRequisicaoPortal', {
                ...parametros, 
                produto : equipamento.TB02112_PRODUTO, 
                abertoDatabit : 't',  
                preto : totalPreto, 
                ciano : totalCiano, 
                magenta : totalMagenta, 
                amarelo : totalAmarelo, 
                observacoes : textoObservacoes, 
                situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A', 
                statusPedido : statusPedido, 
                retirarUsados : retirarUsados               
              }).then(response=>{

                if(response.status == 200 && response.data.status == 'OK'){

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[SUCCESS]', '');

                    Conexao.post('pedidosController/notificarPedidoTonerSucesso', {
                        empresa : equipamento.CMQ00001_EMPRESA, 
                        destino : emailNotificacao,
                        copia : copiaEmail, 
                        assunto : 'Nova Solicitação de Suprimento',
                        mensagem : equipamento, 
                        solicitante : solicitante, 
                        observacoes : observacoes, 
                        ciano : ciano, 
                        magenta : magenta, 
                        amarelo : amarelo, 
                        preto : preto, 
                        situacao : situacao, 
                        statusPedido : statusPedido, 
                        retirarUsados : retirarUsados, 
                        contadorMono : contadorMono, 
                        contadorColor : contadorColor, 
                        
                    }).then(response=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[SUCESS]', '');

                    }).catch(error=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[FAIL]', '');

                    })                     
                   
                } else{

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[FALHA]', response.data.value);

                    Conexao.post('pedidosController/notificarPedidoTonerSucesso', {
                        empresa : equipamento.CMQ00001_EMPRESA, 
                        destino : emailNotificacao,
                        copia : copiaEmail, 
                        assunto : 'Nova Solicitação de Suprimento',
                        mensagem : equipamento, 
                        solicitante : solicitante, 
                        observacoes : observacoes, 
                        ciano : ciano, 
                        magenta : magenta, 
                        amarelo : amarelo, 
                        preto : preto, 
                        situacao : situacao, 
                        statusPedido : statusPedido, 
                        retirarUsados : retirarUsados, 
                        contadorMono : contadorMono, 
                        contadorColor : contadorColor, 
                        
                    }).then(response=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[SUCESS]', '');

                    }).catch(error=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[FAIL]', '');

                    })            
                   
                }
             }).catch(error=>{

                GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[ERROR]', error);

                Conexao.post('pedidosController/notificarPedidoTonerSucesso', {
                    empresa : equipamento.CMQ00001_EMPRESA, 
                    destino : emailNotificacao,
                    copia : copiaEmail, 
                    assunto : 'Nova Solicitação de Suprimento',
                    mensagem : equipamento, 
                    solicitante : solicitante, 
                    observacoes : observacoes, 
                    ciano : ciano, 
                    magenta : magenta, 
                    amarelo : amarelo, 
                    preto : preto, 
                    situacao : situacao, 
                    statusPedido : statusPedido, 
                    retirarUsados : retirarUsados, 
                    contadorMono : contadorMono, 
                    contadorColor : contadorColor, 
                    
                }).then(response=>{

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[SUCESS]', '');

                }).catch(error=>{

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[FAIL]', '');

                }) 
             })
             /*           
            props.history.push({
                pathname : '/solicitacoes-suprimentos/empresa', state:{
                ...props.state,
                filtrar : true, 
                numeroSerie : equipamento.TB02112_NUMSERIE
                }
            })
                 */
                
            
        }else{

            GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[FALHA]', '');

            Conexao.post('pedidosController/insertRequisicaoPortal', {
                ...parametros, 
                abertoDatabit : 'f', 
                preto : totalPreto, 
                ciano : totalCiano, 
                magenta : totalMagenta, 
                amarelo : totalAmarelo, 
                observacoes : textoObservacoes, 
                situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A', 
                statusPedido : statusPedido, 
                retirarUsados : retirarUsados,
                produto : equipamento.TB02112_PRODUTO,             
              }).then(response=>{

                if(response.status == 200 && response.data.status == 'OK'){

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[REDUNDANCIA]-[SUCCESS]', '');

                    Conexao.post('pedidosController/notificarPedidoTonerSucesso', {
                        empresa : equipamento.CMQ00001_EMPRESA, 
                        destino : emailNotificacao,
                        copia : copiaEmail, 
                        assunto : 'Gerar Requisição de Suprimento',
                        mensagem : equipamento, 
                        solicitante : solicitante, 
                        observacoes : observacoes, 
                        ciano : ciano, 
                        magenta : magenta, 
                        amarelo : amarelo, 
                        preto : preto, 
                        situacao : situacao, 
                        statusPedido : statusPedido, 
                        retirarUsados : retirarUsados, 
                        contadorMono : contadorMono, 
                        contadorColor : contadorColor, 
                        produto : equipamento.TB02112_PRODUTO, 
                    }).then(response=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[SUCESS]', '');

                    }).catch(error=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[FAIL]', '');

                    }) 
                   
                } else{

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[REDUNDANCIA]-[FALHA]', ''); 

                    Conexao.post('pedidosController/notificarPedidoTonerSucesso', {
                        empresa : equipamento.CMQ00001_EMPRESA, 
                        destino : emailNotificacao,
                        copia : copiaEmail, 
                        assunto : 'Gerar Requisição de Suprimento',
                        mensagem : equipamento, 
                        solicitante : solicitante, 
                        observacoes : observacoes, 
                        ciano : ciano, 
                        magenta : magenta, 
                        amarelo : amarelo, 
                        preto : preto, 
                        situacao : situacao, 
                        statusPedido : statusPedido, 
                        retirarUsados : retirarUsados, 
                        contadorMono : contadorMono, 
                        contadorColor : contadorColor, 
                        
                    }).then(response=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[SUCESS]', '');

                    }).catch(error=>{

                        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[FAIL]', '');

                    }) 
                   
                }
             }).catch(error=>{

                GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[REDUNDANCIA]-[ERROR]', error);

                Conexao.post('pedidosController/notificarPedidoTonerSucesso', {
                    empresa : equipamento.CMQ00001_EMPRESA, 
                    destino : emailNotificacao,
                    copia : copiaEmail, 
                    assunto : 'Gerar Requisição de Suprimento',
                    mensagem : equipamento, 
                    solicitante : solicitante, 
                    observacoes : observacoes, 
                    ciano : ciano, 
                    magenta : magenta, 
                    amarelo : amarelo, 
                    preto : preto, 
                    situacao : situacao, 
                    statusPedido : statusPedido, 
                    retirarUsados : retirarUsados, 
                    contadorMono : contadorMono, 
                    contadorColor : contadorColor, 
                    
                }).then(response=>{

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[SUCESS]', '');

                }).catch(error=>{

                    GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[NOTIFICACAO]-[FAIL]', '');

                }) 
             })  
                       
        }
        Swal.fire({
            title : 'Corporate Web', 
            text : 'Requisição de Suprimento aberta com sucesso', 
            icon : 'success', 
            showConfirmButton : false, 
            showCancelButton : false, 
            timer : 2500
        })
        setTimeout(()=>{
            setOpen(false)
        }, 3000)
        

    }).catch(error=>{

        GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[ERROR]', error);

        Conexao.post('pedidosController/insertRequisicaoPortal', {
            ...parametros, 
            abertoDatabit : 'f',
            preto : totalPreto, 
            ciano : totalCiano, 
            magenta : totalMagenta, 
            amarelo : totalAmarelo, 
            observacoes : textoObservacoes, 
            situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A', 
            statusPedido : statusPedido, 
            retirarUsados : retirarUsados , 
            produto : equipamento.TB02112_PRODUTO,                 
          }).then(response=>{

            if(response.status == 200 && response.data.status == 'OK'){

                GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[REDUNDANCIA]-[SUCCESS]', '');
                               
            } else{

                GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[REDUNDANCIA]-[FALHA]', '');   
                   
            }
         }).catch(error=>{

            GeradorLogErro(props.dadosEmpresa, '[MODAL SUPRIMENTOS]-['+equipamento.TB02112_NUMSERIE+']-[CM02018]-[REDUNDANCIA]-[ERROR]', error);

         })  
               
    })

    Swal.fire({
        title : 'Corporate Web', 
        text : 'Requisição de Suprimento aberta com sucesso', 
        icon : 'success', 
        showConfirmButton : false, 
        showCancelButton : false, 
        timer : 2500
    })
    setTimeout(()=>{
        setOpen(false)
    }, 3000)
}
const getDetalhesColetaV1 = async () =>{
  
    Conexao.post('equipamentosController/getLeiturasPrinterconnectV1', {
      numeroSerie : equipamento.TB02112_NUMSERIE, 
      empresas : 2, 
     
    }).then(response =>{
      if(response.data.status == 'OK'){
       
            if(coleta && !coleta.idcoleta){
                setContadorMono(response.data.payload.contadormonototal)
                setContadorColor(response.data.payload.contadorcolortotal)

            }
        
      }else{
       
       
      }
     
    }).catch(error =>{
     
     
      
    })
  }


//getNotasFiscaisContrato
  return (
    <div>

      {props.botaoGrande ?
       
        <Button onClick={handleClickOpen}  variant='contained' className='button-default large-button' fullWidth elevation={0}><Cart /> Suprimento</Button> :
        <Button onClick={handleClickOpen} className='button-default-outlined'><CartIcon/></Button> }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'lg'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        scroll='body'>
        <DialogTitle id="alert-dialog-title">
        <Grid container>
                <Grid item xs = {2}>
                <ModalVideo gerarLogSite={(op)=>props.gerarLogSite('Modal Ajuda Requisiçoes')} >
                    <iframe 
                        width="900" 
                        height="500" 
                        src="https://www.youtube.com/embed/rtccDtxwPqM" 
                        title="Como abrir um Novo Pedido de Suprimentos." 
                        frameborder="0" 
                        allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >                            
                    </iframe>                            
                </ModalVideo>
                </Grid>
            </Grid>
            <Grid item xs ={10}>
                Novo Pedido de Suprimentos
            </Grid>

            
        </DialogTitle>
        <DialogContent dividers >         
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
            <Grid item xs ={1}>
                    <Typography variant='subtitle2'>Patrimônio</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_PAT}</Typography>
                </Grid>
                <Grid item xs ={3}>
                    <Typography variant='subtitle2'>Número de Série</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_NUMSERIE}</Typography>
                </Grid>
                
                <Grid item xs ={5}>
                    <Typography variant='subtitle2'>Modelo</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB01010_NOME}</Typography>
                </Grid>
                <Grid item xs ={3}>
                    <Typography variant='subtitle2'>Setor</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02177_NOME }</Typography>
                </Grid>
            </Grid>
           
            
            
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs = {2}>
                    <TextField
                       InputProps={{
                        startAdornment : <InputAdornment position='start'><InkIcon style={{color:'#1565C0'}}/></InputAdornment>}}
                        label='Ciano'
                        disabled={equipamento.TB02112_TIPOEQUIP =='C'}
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        select
                        SelectProps={{native:true}}
                        value={ciano}
                        onChange={(e)=>setCiano(e.target.value.replace(/[^0-5]/g,''))}                       
                        fullWidth>
                            <option value= '0'>0</option>
                            <option value= '1'>1</option>
                            <option value= '2'>2</option>
                            <option value= '3'>3</option>
                            <option value= '4'>4</option>
                            <option value= '5'>5</option>
                    </TextField>
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Magenta'
                        InputProps={{
                            startAdornment : <InputAdornment position='start'><InkIcon style={{color:'#D32F2F'}}/></InputAdornment>}}
                        disabled={equipamento.TB02112_TIPOEQUIP =='C'}
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        select
                        value={magenta}
                   
                        onChange={(e)=>setMagenta(e.target.value.replace(/[^0-5]/g,''))}
                        SelectProps={{native:true}}
                        fullWidth>
                        <option value= '0'>0</option>
                        <option value= '1'>1</option>
                        <option value= '2'>2</option>
                        <option value= '3'>3</option>
                        <option value= '4'>4</option>
                        <option value= '5'>5</option>
                </TextField>
                </Grid>
            
                <Grid item xs = {2}>
                    <TextField
                        label='Amarelo'
                        InputProps={{
                            startAdornment : <InputAdornment position='start'><InkIcon style={{color:'#FFC107'}}/></InputAdornment>}}
                        disabled={equipamento.TB02112_TIPOEQUIP =='C'}
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        select
                        value={amarelo}
            
                        onChange={(e)=>setAmarelo(e.target.value.replace(/[^0-5]/g,''))}
                        SelectProps={{native:true}}
                        fullWidth>
                        <option value= '0'>0</option>
                        <option value= '1'>1</option>
                        <option value= '2'>2</option>
                        <option value= '3'>3</option>
                        <option value= '4'>4</option>
                        <option value= '5'>5</option>
                </TextField>
                </Grid>
                <Grid item xs = {2}>
                    <TextField
                        label='Preto'
                        InputProps={{
                            startAdornment : <InputAdornment position='start'><InkIcon style={{color:'#000'}}/></InputAdornment>}}
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        select
                        value={preto}
                     
                        onChange={(e)=>setPreto(e.target.value.replace(/[^0-5]/g,''))}
                        SelectProps={{native:true}}
                        fullWidth>
                        <option value= '0'>0</option>
                        <option value= '1'>1</option>
                        <option value= '2'>2</option>
                        <option value= '3'>3</option>
                        <option value= '4'>4</option>
                        <option value= '5'>5</option>
                </TextField>
                </Grid>

                <Grid item xs = {2}>
                    <TextField
                        label='Contador Monocromático'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        inputRef={refMono}
                        value={contadorMonocromatico}
                        onChange={(e)=>{
                            if(e.target.value.length <=7){
                                setContadorMonocromatico(parseInt(somenteNumeros(e.target.value)))
                            }
                        }}
                        onBlur={(e)=>{
                            if(e.target.value == ''){
                                setContadorMonocromatico(0)
                            }
                        }}
                        fullWidth/>
                </Grid>

                <Grid item xs = {2}>
                    <TextField
                        label='Contador Colorido'                        
                        InputLabelProps={{shrink:true}}
                        inputRef={refColor}
                        onChange={(e)=>{
                            if(e.target.value.length <=7){
                                setContadorColorido(parseInt(somenteNumeros(e.target.value)))
                            }
                        }}
                        onBlur={(e)=>{
                            if(e.target.value == ''){
                                setContadorColorido(0)
                            }
                        }}
                        variant='outlined'
                        value={contadorColorido}
                        error={equipamento.COLORIDO !='T' && (submitForm && contadorColor == '') ? 'form-error' : ''}
                        helperText={equipamento.COLORIDO !='T' && (submitForm && contadorColor == '') ? 'Contador Inválido' : ''}
                        fullWidth/>
                </Grid>
                </Grid>
                <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                <Grid item xs = {4}>
                    <TextField
                        label='Solicitante'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={solicitante}
                        onChange={(e)=>setSolicitante(somenteTexto(e.target.value))}
                        className={ (submitForm && solicitante == '') ? 'form-error' : ''}
                        helperText={ (submitForm && solicitante == '') ? 'Informe o nome do solicitante' : ''}
                        fullWidth/>
                </Grid>
                <Grid item xs = {8}>
                    <TextField
                        label='Email do solicitante'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        disabled={travarSolicitante}
                        value={emailSolicitante}
                        onChange={(e)=>setEmailSolicitante(e.target.value)}
                        className={ (submitForm && emailSolicitante == '') ? 'form-error' : ''}
                        helperText={ (submitForm && emailSolicitante == '') ? 'Informe o email do solicitante' : ''}
                        fullWidth/>
                        <Typography variant='caption' style ={{fontSize:'9px'}}>Cópias da notificação para: {(contatos != '' ? contatos :  equipamento.TB02176_EMAIL)  + (emailSolicitante != '' ? ','+emailSolicitante : '')}</Typography>
                </Grid>
                
                </Grid>
                <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                    <Grid item xs = {3}>
                        <TextField
                            label='Situação do equipamento'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            select
                            value={situacao}
                            onChange={(e)=>setSituacao(e.target.value)}    
                            SelectProps={{native:true}}
                            fullWidth>
                            <option value= 'Equipamento Normal'>Equipamento Normal</option>
                            <option value= 'Sem toner'>Sem toner</option>
                        </TextField>
                    </Grid>
                    <Grid item xs = {2}>
                        <TextField
                            label='Status do pedido'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            select
                            SelectProps={{native:true}}
                            value={statusPedido}
                            onChange={(e)=>setStatusPedido(e.target.value)}                            
                            fullWidth>
                            <option value= 'Toner reserva'>Toner reserva</option>
                            <option value= 'Toner com defeito'>Toner com defeito</option>
                        </TextField>
                    </Grid>
                    
                    <Grid item xs = {2}>
                        <TextField
                            label='Solicitar retirada usados?'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            select
                            SelectProps={{native:true}}
                            value={retirarUsados}
                            onChange={(e)=>setRetirarUsados(e.target.value)}                            
                            fullWidth>
                            <option value= 'Não'>Não</option>
                            <option value= 'Sim'>Sim</option>
                        </TextField>
                    </Grid>
                    
                    <Grid item xs = {5} >
                        <TextField
                            label='Observações do pedido (Máximo 500 caracteres)' 
                            variant='outlined'
                            multiline
                            rows={4}
                            rowsMax={4}
                            fullWidth
                            value={observacoes}
                            onChange={(e)=>{
                                if(e.target.value.length <= 500){
                                    setObservacoes(somenteTexto(e.target.value))
                                }
                            }} 
                            className={ (submitForm && (statusPedido == 'Toner com defeito' && observacoes == '')) ? 'form-error' : ''}
                            helperText={observacoes.length > 0 ? 'Restam ' + (500 - observacoes.length)+' caracteres.' : ''}
                            InputLabelProps={{shrink:true}}/>
                    </Grid>
                    
                </Grid>

            <DetalhesColeta 
                resumido={true} 
                setColeta={(coleta)=>setColeta(coleta)} 
                sizeDiplay={60} 
                setLoading={(opt)=>props.setLoading(opt)} 
                numeroSerie={equipamento.TB02112_NUMSERIE}  
                dadosEmpresa = {props.dadosEmpresa} 
                empresa={props.empresa}/>
            <Grid item xs={12} style ={{ marginTop:'10px'}}>
                    <Alert severity='warning'>* Não esqueça de solicitar a retirada dos suprimentos usados ou com defeito.</Alert>
                    </Grid>
            <Grid container spacing = {1} style ={{marginTop:'50px', paddingBottom:'50px'}}>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
                <Button className='button-error' botaoGrande={true} disabled={props.loading || loading} color='secondary' onClick={()=>{
                    setOpen(false)
                }}>Cancelar</Button>
            </Grid>
            <Grid item xs={2}>
                <Button  className='button-success' botaoGrande={true} disabled={props.loading || loading} onClick={()=>{
                        // destino : emailSolicitante ? emailSolicitante : equipamento.TB02176_EMAIL, 
                        if((ciano == 0) && (magenta== 0) && (amarelo == 0) && (preto == 0) ){
                            Swal.fire({
                                title : 'Gestão de Suprimentos', 
                                text : 'Você precisa informar a quantidade de pelo menos uma cor!', 
                                icon : 'warning', 
                                showConfirmButton : false, 
                                showCancelButton : false, 
                                confirmButtonText : 'Sim', 
                                cancelButtonText : 'Não', 
                                timer : 1500,
                                backdrop :"rgba(255, 0, 0, 0.2)"
                            })
                            return
                        }
                        
                        if((refMono.current && refMono.current.value ==  '') || (refColor.current && refColor.current.value ==  '') ){
                            Swal.fire({
                                title : 'Corporate - Suprimentos', 
                                text :'Verifique o valor informado para os campos de contadores (medidores)!', 
                                icon:'warning', 
                                showConfirmButton:false, 
                                confirmButtonText : 'OK', 
                                showCancelButton:false,
                                backdrop :"rgba(255, 0, 0, 0.2)",
                                timer : 1500,
                            })
                            return
                         } 
                         
                         if((parseInt(contadorMonocromatico) + parseInt(contadorColorido)) >= 9999999){
                            Swal.fire({
                                title : 'Corporate - Suprimentos', 
                                text :'Verifique o valor informado para os campos de contadores (medidores)!', 
                                icon:'warning', 
                                showConfirmButton:false, 
                                confirmButtonText : 'OK', 
                                showCancelButton:false,
                                backdrop :"rgba(255, 0, 0, 0.2)",
                                timer : 1500,
                            })
                            return
                         }

                         

                        

                        if(solicitante == '' || emailSolicitante == '' ){
                            Swal.fire({
                                title : 'Gestão de Suprimentos', 
                                text : 'Você precisa informar todos os campos', 
                                icon : 'warning', 
                                showConfirmButton : false, 
                                showCancelButton : false, 
                                confirmButtonText : 'Sim', 
                                cancelButtonText : 'Não', 
                                timer : 1500,
                                backdrop :"rgba(255, 0, 0, 0.2)"
                            })

                            return
                        }
                                             

 
                        if(statusPedido == 'Toner com defeito' && observacoes == ''){
                            Swal.fire({
                                title : 'Corporate - Suprimentos', 
                                text :'Você informou defeito no toner, por favor, nos informe qual seria o defeito apresentado!', 
                                icon:'warning', 
                                showConfirmButton:true, 
                                confirmButtonText : 'OK', 
                                showCancelButton:false,
                                backdrop :"rgba(255, 0, 0, 0.2)"
                            })
                            return
                        } 
                    Swal.fire({
                        title : 'Gestão de Suprimentos', 
                        text : 'Deseja realmente gravar esta solicitação de toner?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        showCancelButton : true, 
                        confirmButtonText : 'Sim', 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            setSubmitForm(true)
                            
                            insertPedidoSuprimentoDatabit();

                        }
                    })
                }}>Gravar</Button>
            </Grid>                 
            </Grid>
            
        </DialogContent>
       
      </Dialog>
    </div>
  );
}
export default withRouter(ModalPedidoSuprimentoV2)