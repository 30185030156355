import React , {useEffect, useState, useRef} from 'react';
import { Typography, Divider,Switch,FormControlLabel, TextField, Grid, InputAdornment, Button, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, LinearProgress} from '@material-ui/core';
import Swal from 'sweetalert2';
import Conexao from '../providers/Api/Api'
import {withRouter} from 'react-router-dom'
import somenteTexto from '../providers/somenteTexto';
import { AlertTitle, Alert } from '@material-ui/lab';
import Dataservice from '../providers/Api/Dataservice';


const SolicitacaoSuprimentos = (props) => {
    const url = new URL(window.location.href);
    const {payload, coleta} =props.location.state
    const {equipamento}=props.location.state.payload
    const [contadorMono, setContadorMono] = useState(0)
    const [contadorColor, setContadorColor] = useState(0)
    const [ciano, setCiano] = useState(0)
    const [magenta, setMagenta] = useState(0)
    const [amarelo, setAmarelo] = useState(0)
    const [preto, setPreto] = useState(0)
    const [defeito, setDefeito] = useState(false)
    const [observacoes, setObservacoes] = useState('')
    const [contato, setContato] = useState('')
    const [emailContato, setEmailContato] = useState('')
    const [retirarUsados, setRetirarUsaros] = useState(false)



    useEffect(()=>{
        if(coleta.idcoleta){
            setContadorMono(coleta.monototal)
            setContadorColor(coleta.colortotal)
        }else{
            setContadorMono(0)
            setContadorColor(0)
        }
      }, [coleta])

    return <div style={{width :'100', height: '100vh', backgroundColor:'#f9f9f9', padding : '20px', paddingTop : '5px'}}>
            <div style ={{backgroundColor:"#444",padding :'5px', textAlign :"center", zIndex :"9999999999" }}>
                <span style={{color:"#fff"}}>Solicitação de Suprimentos</span>
            </div>
            <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop :'10px'}}>                
                <Grid container spacing={2} >
                    <Grid item xs={2}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}>PAT</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02112_PAT : null}</strong></Typography>
                    </Grid>            
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}>SERIAL</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02112_NUMSERIE : null}</strong></Typography>
                    </Grid>            
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}>MODELO</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB01010_NOME : null}</strong></Typography>
                    </Grid>            
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}>Site</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02176_NOME : null}</strong></Typography>
                    </Grid>            
                </Grid>
            </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Informação de contadores</Typography>
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Contador Monocromático'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        value={contadorMono}                        
                        onChange={(e)=>setContadorMono(e.target.value)}
                        variant='outlined'/>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Contador Color'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        value={contadorColor}
                        onChange={(e)=>setContadorColor(e.target.value)}
                        variant='outlined'/>
                </Grid>
            </Grid>

            {

                coleta.dias > 0 
                ? 
                <Alert severity="error" style={{marginTop :'5px'}}>
                    <AlertTitle>Atenção</AlertTitle>
                    <Typography variant='caption'>Sua leitura é de uma data superior há 3 dias. verifique!</Typography>
                </Alert> 
                : 
                null

            }
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Solicitação</Typography>            
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs={3}>
                    <TextField 
                        label='C'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        select
                        SelectProps={{native:true}}
                        helperText={(coleta &&  coleta.idcoleta && coleta.nivelciano ? coleta.nivelciano+'%' : '')}
                        value={ciano}
                        onChange={(e)=>setCiano(e.target.value)}
                        >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='M'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        variant='outlined' select
                        SelectProps={{native:true}}
                        helperText={(coleta &&  coleta.idcoleta && coleta.nivelmagenta ? coleta.nivelmagenta+'%' : '')}
                        value={magenta}
                        onChange={(e)=>setMagenta(e.target.value)}
                        >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Y'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        variant='outlined' select
                        SelectProps={{native:true}}
                        helperText={( coleta && coleta.idcoleta && coleta.nivelamarelo ? coleta.nivelamarelo+'%' : '')}
                        value={amarelo}
                        onChange={(e)=>setAmarelo(e.target.value)}
                        >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </TextField>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='K'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        variant='outlined' select
                        SelectProps={{native:true}}
                        helperText={(coleta && coleta.idcoleta && coleta.nivelpreto ? coleta.nivelpreto+'%' : '')}
                        value={preto}
                        onChange={(e)=>setPreto(e.target.value)}
                        >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </TextField>
                </Grid>
            </Grid>
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Informação de contadores</Typography>
            <Grid container spacing={2} style={{marginTop : '5px'}}>
                <Grid item xs={12}>
                <FormControlLabel control={<Switch checked={defeito} onClick={()=>setDefeito(!defeito)} />} label="Troca motivada por defeito" />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Descreva o defeito apresentado'
                        fullWidth
                        size='large'
                        multiline
                        rows={3} // Define o número de linhas visíveis                        
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={observacoes}
                        disabled={!defeito}
                        placeholder ={!defeito ? 'Indique que é uma troca por defeito e descreva o defeito apresentado':'Descreva o defeito apresentado pelo toner em uso'}
                        onChange={(e)=>setObservacoes(e.target.value)}
                        />
                </Grid>

                <Grid item xs={12}>
                    <FormControlLabel control={<Switch checked={retirarUsados} onClick={()=>setRetirarUsaros(!retirarUsados)} />} label="Retirar toner vazio?" />
                </Grid>
            </Grid>
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Informação do Solicitante</Typography>
           
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Nome'
                        fullWidth
                        size='large'  
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={contato}
                        onChange={(e)=>setContato(e.target.value)}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Email de contato'
                        fullWidth
                        size='large'  
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={emailContato}
                        onChange={(e)=>setEmailContato(e.target.value)}
                        />
                </Grid>
            </Grid>
        </div>

        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button className='btn btn-warning' variant='contained' color='secondary' fullWidth size='large' style={{height : '80px'}} onClick={()=>{
                            Swal.fire({
                                title :'Corporate WEB', 
                                text : 'Deseja cancelar o pedido agora?', 
                                showConfirmButton:true, 
                                confirmButtonText:'Sim', 
                                showCancelButton:true, 
                                cancelButtonText : 'Não',
                            }).then(resp=>{
                                if(resp.isConfirmed){
                                    props.history.push({
                                        pathname : '/qrcode/'+props.match.params.produto+'/'+props.match.params.numeroserie+'/'+props.match.params.cliente, state:{
                                            payload : payload,  
                                            coleta : coleta
                                                            
                                        }
                                      })
                                }
                            })                        
                    }}>Cancelar</Button>    
                </Grid>
                <Grid item xs={6}>
                    <Button className='btn btn-warning' variant='contained' color='primary'fullWidth size='large' style={{height : '80px'}} onClick={()=>{
                        if(preto + ciano + magenta + amarelo == 0){
                            Swal.fire({
                                title : "Corporate", 
                                text : "Selecione a quantida de suprimento antes de continuar!",
                                icon : 'error', 
                                showCancelButton : false, 
                                showConfirmButton : false, 
                                timer : 1500
                              })
                              return
                        }

                        if(contato.trim() == ""){
                            Swal.fire({
                                title : "Corporate", 
                                text : "Informe o nome do solicitante!",
                                icon : 'error', 
                                showCancelButton : false, 
                                showConfirmButton : false, 
                                timer : 1500
                              })
                              return
                        }

                        if(emailContato.trim() == ""){
                            Swal.fire({
                                title : "Corporate", 
                                text : "Informe o email do solicitante!",
                                icon : 'error', 
                                showCancelButton : false, 
                                showConfirmButton : false, 
                                timer : 1500
                              })
                              return
                        }
                            Swal.fire({
                                title :'Corporate WEB', 
                                text : 'Deseja confirmar a requisição de suprimento?', 
                                showConfirmButton:true, 
                                confirmButtonText:'Sim', 
                                showCancelButton:true, 
                                cancelButtonText : 'Não',
                            }).then(resp=>{
                                if(resp.isConfirmed){

                                    let contMono = contadorMono;

                                    let contColor = contadorColor;

                                    let contTotal = parseInt(contMono) + parseInt(contColor);

                                    const isoStr = new Date().toISOString()

                                    let totalPreto = " Quantidade Preto: " + preto ? preto : 0; 

                                    let totalCiano = " Quantidade Ciano: " + ciano ? ciano : 0; 

                                    let totalMagenta = "Quantidade Magenta: " + magenta ? magenta : 0; 

                                    let totalAmarelo = "Quantidade Amarelo: " + amarelo ? amarelo : 0; 

                                    const emailNotificacao =((emailContato != '' ? emailContato :  equipamento.TB02176_EMAIL)  + (emailContato != '' ? ','+emailContato : '')) ;

                                    const textoObservacoes  = somenteTexto(observacoes);

                                    const statusPedido = defeito ? 'Toner com defeito' : 'Toner reserva';

                                    const situacao = equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A'

                                    const parametros = {
                                        codemp : equipamento.TB02176_CODEMP, 
                                        codcli : equipamento.TB02176_CODCLIINT ? equipamento.TB02176_CODCLIINT : props.dadosEmpresa.TB01066_CODCLI,
                                        numserie : equipamento.TB02112_NUMSERIE,  
                                        codsite :equipamento.TB02112_CODSITE != '' ? equipamento.TB02112_CODSITE : null, 
                                        codtec : equipamento.CMQ00001_CODTEC ? equipamento.CMQ00001_CODTEC.padStart(4, '0') : '0000',
                                        operacao : 1, 
                                        conttotal : contTotal, 
                                        contpb : contMono, 
                                        contcolor: contColor, 
                                        condpag :  equipamento.CMQ00001_CONDPAG ? equipamento.CMQ00001_CONDPAG.padStart(3, '0') : '000',
                                        coddepto : equipamento.TB02112_CODDEPTO ? equipamento.TB02112_CODDEPTO : null, 
                                        contrato : equipamento.TB02112_CODIGO, 
                                        data : isoStr, 
                                        dataexec : isoStr, 
                                        dtcad : isoStr,
                                        nome : somenteTexto(contato), 
                                        fone : equipamento.TB02176_FONE ? equipamento.TB02176_FONE.substr(0,11) : null, 
                                        email : emailContato.substr(0, 200) , 
                                        obs : 'Preto: ' + totalPreto+', Ciano: '+
                                                        totalCiano+', Magenta: '+
                                                        totalMagenta+', Amarelo: '+
                                                        totalAmarelo+' - Situação: ' + 
                                                        situacao+', Status Pedido: '+
                                                        statusPedido+', Retirar Usados: '+
                                                        (retirarUsados ? "Sim":"Não")+', Observações: ' + 
                                                        textoObservacoes,                     
                                        vend : equipamento.CMQ00001_VEND ? equipamento.CMQ00001_VEND : '0000' ,
                                        status : equipamento.CMQ00001_STATUS , 
                                        tipodesc :equipamento.TB02176_TIPODESC ? equipamento.TB02176_TIPODESC : equipamento.CMQ00001_TIPODESC.padStart(2, '0') , 
                                        situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A',         
                                        tipofrete : equipamento.CMQ00001_TIPOFRETE ? equipamento.CMQ00001_TIPOFRETE : '0', 
                                        vlrnota : 0, 
                                        qtde : 0
                                }
                                
                                Dataservice.post('RequisicaoController/incluir', {
                                    ...parametros,                       
                            }).then(response=>{
                              
                                if(response.status == 200 && response.data.status.code == '1'){
                                      
                                    Swal.fire({
                                        title: "Corporate", 
                                        text: `Sua requisição foi gerada com sucesso com o número: ${response.data.value.codigo}`,
                                        icon: 'success', 
                                        showCancelButton: false, 
                                        showConfirmButton: true, 
                                      
                                      });
                                     

                                }else{
                                   
                                      Swal.fire({
                                        title : "Corporate", 
                                        text : "Não foi possível incluir",
                                        icon : 'warning', 
                                        showCancelButton : false, 
                                        showConfirmButton : false, 
                                        timer : 1500
                                      })
                                     

                                }

                                props.history.push({
                                    pathname : '/qrcode/'+props.match.params.produto+'/'+props.match.params.numeroserie+'/'+props.match.params.cliente, state:{
                                        payload : payload,  
                                        coleta : coleta                                                        
                                    }
                                })


                            }).catch(error=>{

                                  Swal.fire({
                                    title : "Corporate", 
                                    text : "Não foi possível incluir",
                                    icon : 'error', 
                                    showCancelButton : false, 
                                    showConfirmButton : false, 
                                    timer : 1500
                                  })
                                  

                                  props.history.push({
                                    pathname : '/qrcode/'+props.match.params.produto+'/'+props.match.params.numeroserie+'/'+props.match.params.cliente, state:{
                                        payload : payload,  
                                        coleta : coleta
                                                        
                                    }
                                  })

                            })


                                
                                }
                            })

                        
                    }}>Confirmar</Button>    
                </Grid>
            </Grid>

        </div>
        <br/>
        <br/>
        <br/>
    </div>
}


export default (SolicitacaoSuprimentos) 

