import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetalhesIcon from '@material-ui/icons/FormatListBulleted'
import { TextField, Grid, Table, TableHead, TableBody, TableCell, TableRow, Divider, Typography , Fab, Badge, InputAdornment, CircularProgress, Tooltip} from '@material-ui/core';
import DetalhesColeta from './Printerconnect/GridDisplayToner'
import Swal from 'sweetalert2';
import NivelToner from './Printerconnect/NivelToner'
import Build from '@material-ui/icons/Build'
import Cart from '@material-ui/icons/AddShoppingCart'
import CartIcon from '@material-ui/icons/AddShoppingCart'
import BuildIcon from '@material-ui/icons/Build'
import FileUpload from '../../components/FileUploadJS'
import UploadFileChamado from './UploadFileChamado'
import Dataservice from '../../providers/Api/Dataservice';
import Conexao from '../../providers/Api/Api'
import {withRouter} from 'react-router-dom'
import GeradorLogErro from '../../providers/GeradorLogErro';
import ModalVideo from '../../components/base/ModalVideo';
import GeradorLog from '../../providers/GeradorLog';
import somenteTexto from '../../providers/somenteTexto';
import somenteNumeros from '../../providers/somenteNumeros';


const  ModalAberturaChamado = (props) => {
    const {CMQ00001_CODEMPRESA, CMQ00001_OPERACAO, CMQ00001_TIPODESC, CMQ00001_VEND, CMQ00001_STATUS, CMQ00001_CODTEC, CMQ00001_CONDPAG } = props.dadosEmpresa.operacaoEmpresa
  const [open, setOpen] = React.useState(false);
  const [coleta, setColeta] = React.useState([]);
  const [contadorMono, setContadorMono] = React.useState(0);
  const [contadorColor, setContadorColor] = React.useState(0);
  const [contadorTotal, setContadorTotal] = React.useState(0);
  const [solicitante, setSolicitante]= React.useState('');
  const [emailSolicitante, setEmailSolicitante]= React.useState('');
  const [observacoes, setObservacoes]= React.useState('');
  const [submitForm, setSubmitForm] = React.useState(false);
  const [statusPedido, setStatusPedido] = React.useState('');
  const [equipamentoDatabit, setEquipamentoDatabit]= React.useState([]);
  const [pictures, setPictures] = React.useState([])
  const [copiaEmail, setCopiaEmail] = React.useState('');
  const [situacaoEquipamento, setSituacaoEquipamento]= React.useState('');
  const [travarSolicitante, setTravarSolicitante] = React.useState(false);
  const [contatos, setContatos] = React.useState('');


  

  const refContadorMono = React.useRef();
  const refContadorColor = React.useRef();


    const onDrop = (picture) => {
        let temp = pictures;
        temp.push(picture)
        setPictures(temp)
       
    }

   const handleChange = (e) =>{
        let temp = pictures;
        temp.push(e)
        setPictures(temp)
        console.log(e)
      
        
    
    }

  
  
  const equipamento = props.equipamento
 

  React.useEffect(()=>{
    if(coleta && coleta.numeroserie){
        setContadorMono(coleta.monototal ? coleta.monototal : 0)
        setContadorColor(coleta.colortotal ?  coleta.colortotal : 0)
    }
  },[coleta])

  const handleClickOpen = () => {
    getContatosNotificacao()
    getDetalhesColetaV1();
    setCopiaEmail(props.location.state.logotipo.toUpperCase() == 'UZIPRINT' ? 'atendimento@centermaq.com, atendimento@uziprint.com, andre@centermaq.com, claudenir@centermaq.com' : 'atendimento@centermaq.com, andre@centermaq.com, claudenir@centermaq.com')
    getEquipamentoDatabit();
    setContadorMono(equipamento.CONTADORMONO ? parseInt(equipamento.CONTADORMONO) : 0)
    setContadorColor(equipamento.CONTADORCOLOR ? parseInt(equipamento.CONTADORCOLOR) : 0)
    setColeta([])      
    setOpen(true)
    getHistoricoPedido()
    
  };

  const handleClose = () => {
    setOpen(false);
    
  };

  React.useEffect(()=>{
    if(open){
        GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-[FECHADO]')
    }else{
        
    }
  }, [open])

  const getHistoricoPedido = () =>{
      props.Conexao.post('tonerControllerV2?empresa='+props.empresa, {
          codigoCliente : props.dadosEmpresa.CODIGOCLIENTE, 
          numeroSerie:equipamento.TB02112_NUMSERIE
      }).then(response=>{

      }).catch(error=>{

      });
  }


  const getContatosNotificacao = () =>{
  
    Conexao.post('clientesController/getContatosNotificacao?codigoEmpresa=', {
        codigoCliente : equipamento.TB02111_CODCLI, 
        codigoEmpresa : equipamento.TB02176_CODEMP, 
        codigoContrato : equipamento.TB02112_CODIGO, 
        codigoSite : equipamento.TB02112_CODSITE, 
        codigoDepartamento : equipamento.TB02112_CODDEPTO, 
        filtrar : 't', 
        tipoNotificacao :'chamado'
       
    }).then(response=>{
        
        if(response.data.status =='OK'){
            
            setContatos(response.data.payload)
         
            
        }else{
            setContatos([])
        
        }
       
    }).catch(error=>{
        setContatos('')
       
       
        
    })
}


  const getEquipamentoDatabit = () =>{
    
    
    Dataservice.post('EquipamentoController/listar', {
        filtro : "situacao = 'A' and numserie = '"+equipamento.TB02112_NUMSERIE+"' "
    }).then(response=>{
        if(response.status == 200 && response.data.status.code == '1'){
            setEquipamentoDatabit(response.data.value[0])
          
        }else{
            setEquipamentoDatabit([])
        }
    }).catch(error=>{
        setEquipamentoDatabit([])
    })
}

const getDetalhesColetaV1 = async () =>{
  
    Conexao.post('equipamentosController/getLeiturasPrinterconnectV1', {
      numeroSerie : equipamento.TB02112_NUMSERIE, 
      empresas : 2, 
     
    }).then(response =>{
      if(response.data.status == 'OK'){
       
            if(coleta && !coleta.idcoleta){
                setContadorMono(response.data.payload.contadormonototal)
                setContadorColor(response.data.payload.contadorcolortotal)
                
            }
        
      }else{
       
       
      }
     
    }).catch(error =>{
     
     
      
    })
  }

//getNotasFiscaisContrato
  return (
    <div>

      {props.botaoGrande ? <Button onClick={handleClickOpen}  variant='contained' className='button-default large-button' fullWidth elevation={0}><BuildIcon /> Ordem Serviço</Button> :<Button onClick={handleClickOpen} className='button-default-outlined'><BuildIcon/></Button> }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth = 'lg'
        fullWidth
        hideBackdrop
        disableEscapeKeyDown
        disableBackdropClick
        scroll='body'>
        <DialogTitle id="alert-dialog-title">
            <Grid container>
                <Grid item xs = {2}>
                <ModalVideo  gerarLogSite={(op)=>props.gerarLogSite('Modal Ajuda Chamados')}>
                    <iframe 
                        width="900" 
                        height="500" 
                        src="https://www.youtube.com/embed/jLWpovBGVd0" 
                        title="Como abrir uma Ordem de Serviço." 
                        frameborder="0" 
                        allow=" autoplay; clipboard-write; encrypted-media; gyroscope; " autoplay >                            
                    </iframe>                            
                </ModalVideo>
                </Grid>
            </Grid>
            <Grid item xs ={10}>
                Abertura de Ordem de Serviço
            </Grid>
        
           </DialogTitle>
        <DialogContent dividers style ={{paddingLeft:'6%', paddingRight:'6%'}}>         
            <Grid container spacing={1} style ={{marginTop:'10px'}}>
                <Grid item xs ={1}>
                    <Typography variant='subtitle2'>Patrimônio</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_PAT}</Typography>
                </Grid>
                <Grid item xs ={3}>
                    <Typography variant='subtitle2'>Número de Série</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02112_NUMSERIE}</Typography>
                </Grid>
                
                <Grid item xs ={5}>
                    <Typography variant='subtitle2'>Modelo</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB01010_NOME}</Typography>
                </Grid>
                <Grid item xs ={3}>
                    <Typography variant='subtitle2'>Setor</Typography>
                    <Typography variant='subtitle1'>{equipamento.TB02177_NOME }</Typography>
                </Grid>
            </Grid>
         
            <Grid container spacing = {1} style ={{marginTop:'10px'}}>

                <Grid item xs = {2}>
                    <TextField
                        label='Contador Monocromático'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={contadorMono} 
                        inputRef={refContadorMono}                                          
                        onChange={(e)=>{                            
                            if(e.target.value.length <=7){
                                setContadorMono(parseInt(somenteNumeros(e.target.value)))
                            }
                        }}
                        onBlur={(e)=>{
                            if(e.target.value == ''){
                                setContadorMono(0)
                            }
                        }}  
                        fullWidth/>
                </Grid>

                <Grid item xs = {2}>
                    <Tooltip>
                        <TextField
                            label='Contador Colorido'                    
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            value={contadorColor}
                            inputRef={refContadorColor}
                            onBlur={(e)=>{
                                if(e.target.value == ''){
                                    setContadorColor(0)
                                }
                            }}                                            
                            onChange={(e)=>{                            
                                if(e.target.value.length <=7){
                                    setContadorColor(parseInt(somenteNumeros(e.target.value)))
                                }
                            }}
                            error={equipamento.COLORIDO !='T' && (submitForm && contadorColor == '') ? 'form-error' : ''}
                            helperText={equipamento.COLORIDO !='T' && (submitForm && contadorColor == '') ? 'Contador Inválido' : ''}
                            fullWidth/>
                    </Tooltip>
                </Grid>
                <Grid item xs = {3}>
                    <TextField
                        label='Solicitante'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={solicitante}
                        onChange={(e)=>setSolicitante(somenteTexto(e.target.value))}
                        placeholder='Informe o nome do solicitante'
                        className={ (submitForm && solicitante == '') ? 'form-error' : ''}
                        helperText={ (submitForm && solicitante == '') ? 'Informe o nome do solicitante' : ''}
                        fullWidth/>
                </Grid>
                <Grid item xs = {5}>
                    <TextField
                        label='Email do solicitante'                        
                        InputLabelProps={{shrink:true}}
                        disabled={travarSolicitante}
                        variant='outlined'
                        value={emailSolicitante}
                        placeholder='Informe o email de contato do solicitante'
                        onChange={(e)=>setEmailSolicitante(e.target.value)}
                        className={ (submitForm && emailSolicitante == '') ? 'form-error' : ''}
                        helperText={ (submitForm && emailSolicitante == '') ? 'Informe o email do solicitante' : ''}
                        fullWidth/>
                         <Typography variant='caption' style ={{fontSize:'9px'}}>Cópias da notificação para: {(contatos != '' ? contatos :  equipamento.TB02176_EMAIL)  + (emailSolicitante != '' ? ','+emailSolicitante : '')}</Typography>
                </Grid>
                </Grid>
                <Grid container spacing = {1} style ={{marginTop:'10px'}}>
                    <Grid item xs = {3}>
                        <TextField
                            label='Situação do equipamento'
                            InputLabelProps={{shrink:true}}
                            variant='outlined'
                            select
                            value={situacaoEquipamento}
                            onChange={(e)=>setSituacaoEquipamento(e.target.value)}
                            SelectProps={{native:true}}
                            fullWidth>
                            <option value= 'Equipamento Normal'>Equipamento Normal</option>
                            <option value= 'Fazendo barulho'>Fazendo barulho</option>
                            <option value= 'Manchando'>Manchando</option>
                            <option value= 'Equipamento inoperante'>Equipamento inoperante</option>
                            <option value= 'Enroscando Papel'>Enroscando Papel</option>
                        </TextField>
                    </Grid>
                    <Grid item xs = {9} >
                        <TextField
                            label='Defeito apresentado pelo equipamento (Máximo 500 caracteres)' 
                            variant='outlined'
                            multiline
                            rows={4}
                            rowsMax={4}
                            fullWidth
                            value={observacoes}
                            placeholder='Descreva o defeito apresentado pelo equipamento'
                            onChange={(e)=>{
                                if(e.target.value.length <= 500){
                                    setObservacoes(somenteTexto(e.target.value))
                                }
                            }}  
                            className={ (submitForm && observacoes == '') ? 'form-error' : ''}
                            helperText={observacoes.length > 0 ? 'Restam ' + (500 - observacoes.length)+' caracteres.' : ''}
                            InputLabelProps={{shrink:true}}/>
                    </Grid>
                </Grid>                
            
            <DetalhesColeta 
                resumido={true} 
                setColeta={(coleta)=>setColeta(coleta)} 
                sizeDiplay={30} 
                setLoading={(opt)=>props.setLoading(opt)} 
                numeroSerie={equipamento.TB02112_NUMSERIE}  
                dadosEmpresa = {props.dadosEmpresa} 
                empresa={props.empresa}/>
            {/*=<UploadFileChamado pictures={pictures} handleChange = {(item)=>handleChange(item)} onDrop ={(item)=>onDrop(item)}/>*/}
            <Grid container spacing = {1} style ={{marginTop:'50px', paddingBottom:'50px'}}>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
                <Button className='button-error' botaoGrande={true} color='secondary' onClick={()=>{
                    GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-[FECHADO] ')
                    setOpen(false)
                }}>Cancelar</Button>
            </Grid>
            <Grid item xs={2}>
                <Button  className='button-success' botaoGrande={true} onClick={()=>{
                    
                    GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-[ACTION]-[ONCLICK]')
                    Swal.fire({
                        title : 'Gestão de Ordens de Serviço', 
                        text : 'Deseja realmente gravar esta solicitação de abertura de Ordem de Serviço?', 
                        icon : 'question', 
                        showConfirmButton : true, 
                        showCancelButton : true, 
                        confirmButtonText : 'Sim', 
                        cancelButtonText : 'Não'
                    }).then(resposta=>{
                        if(resposta.isConfirmed){
                            GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-[ACTION]-[ISCONFIRMED]')
                            
                            setSubmitForm(true)
                            const currentDate = new Date();
                            currentDate.setHours(currentDate.getHours() - 3);
                            const isoStr = currentDate.toISOString();
                            setContadorTotal(parseInt(contadorMono) + parseInt(contadorColor))

                            if(solicitante == '' || emailSolicitante == '' || observacoes == '' ){
                                Swal.fire({
                                    title : 'Corporate - Suprimentos', 
                                    text :'Você precisa informar todos os dados obrigatórios!', 
                                    icon:'warning', 
                                    showConfirmButton:true, 
                                    confirmButtonText : 'OK', 
                                    showCancelButton:false,
                                    backdrop :"rgba(255, 0, 0, 0.2)"
                                })
                                GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[ERRO-FORM]-[CAMPOS OBRIGATORIOS]')
                                
                                return
                            }

                            if((refContadorMono.current && refContadorMono.current.value ==  '') || (refContadorColor.current && refContadorColor.current.value ==  '') ){
                                Swal.fire({
                                    title : 'Corporate - Suprimentos', 
                                    text :'Verifique o valor informado para os campos de contadores (medidores)!', 
                                    icon:'warning', 
                                    showConfirmButton:false, 
                                    confirmButtonText : 'OK', 
                                    showCancelButton:false,
                                    backdrop :"rgba(255, 0, 0, 0.2)",
                                    timer : 1500,
                                })
                                return
                             }

                             if(contadorTotal >= 9999999){
                                Swal.fire({
                                    title : 'Corporate - Suprimentos', 
                                    text :'Verifique o valor informado para os campos de contadores (medidores)!', 
                                    icon:'warning', 
                                    showConfirmButton:false, 
                                    confirmButtonText : 'OK', 
                                    showCancelButton:false,
                                    backdrop :"rgba(255, 0, 0, 0.2)",
                                    timer : 1500,
                                })
                                return
                             }



                             GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[ERRO-VALIDADO]-[SUCESS]')
                            
                            //codcli : equipamento.TB02176_CODCLIINT ? equipamento.TB02176_CODCLIINT : props.dadosEmpresa.TB01066_CODCLI,
                            const emailNotificacao =((contatos != '' ? contatos :  equipamento.TB02176_EMAIL)  + (emailSolicitante != '' ? ','+emailSolicitante : '')) 
                            const textoObservacoes = somenteTexto(observacoes);
                            const parametros = {
                                    cadastro : isoStr, 
                                    operador : 'CORPORATE WEB',
                                    alteracao :  null, 
                                    opaltera : null,
                                    contrato : equipamento.TB02112_CODIGO, 
                                    codcli : equipamento.TB02111_CODCLI,
                                    produto : equipamento.TB02112_PRODUTO, 
                                    numserie : equipamento.TB02112_NUMSERIE, 
                                    data : isoStr, 
                                    atendente : 'CORPORATE WEB', 
                                    codemp : (equipamento.TB02176_CODEMP != null && equipamento.TB02176_CODEMP != '') ? equipamento.TB02176_CODEMP : CMQ00001_CODEMPRESA, 
                                    situacao :  equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A',  
                                    motivo : textoObservacoes ? textoObservacoes.substr(0,50) : '', 
                                    preventiva : 'N', 
                                    obs : textoObservacoes, 
                                    fecha : null, 
                                    reincidencia : 'N', 
                                    numos : null, 
                                    origem : 'C', 
                                    codtec : equipamento.CMQ00001_CODTEC ? equipamento.CMQ00001_CODTEC.padStart(4, 0) : '0000', 
                                    tipointerv : 'I', 
                                    statusos : '01', 
                                    ordem : 0, 
                                    contpb : contadorMono ? parseInt(contadorMono) : 0, 
                                    contcolor : contadorColor ? parseInt(contadorColor) : 0, 
                                    conttotal :contadorTotal ? parseInt(contadorTotal) : 0 ,
                                    logradouro:equipamento.TB02112_END, 
                                    num: equipamento.TB02112_NUM, 
                                    comp: (equipamento.TB02112_COMP && equipamento.TB02112_COMP != '') ? equipamento.TB02112_COMP.substr(0,20) : null, 
                                    bairro : equipamento.B02112_BAIRRO  ? equipamento.B02112_BAIRRO.substr(30) : null, 
                                    cidade : equipamento.TB02112_CIDADE , 
                                    estado : equipamento.TB02112_ESTADO , 
                                    cep : equipamento.TB02112_CEP, 
                                    fone : equipamento.B02112_FONE, 
                                    foneaux : equipamento.B02112_FONE, 
                                    local : equipamento.TB02112_LOCAL ? equipamento.TB02112_LOCAL.substr(0,200) : null, 
                                    site : equipamento.TB02112_CODSITE ? equipamento.TB02112_CODSITE : null,
                                    depto : equipamento.TB02112_CODDEPTO ? equipamento.TB02112_CODDEPTO  : null, 
                                    contato : somenteTexto(solicitante), 
                                    solicitante : somenteTexto(solicitante), 
                                    celular : '', 
                                    email : emailNotificacao ? emailNotificacao.substr(0, 200)  : ''                                 
                                    
                                }
                                if(parametros.codsite == '' ){
                                    parametros.codsite = null;  
                                }
                            
                                if(parametros.coddepto == '' ){
                                    parametros.coddepto = null;  
                                }
                              
                               
                                // emailSolicitante ? emailSolicitante : equipamento.TB02176_EMAIL
                                
                                GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]')
                                Dataservice.post('OsController/incluir', {
                                    ...parametros
                                }).then(response=>{
                                    
                                    if(response.status == 200 && response.data.status.code == '1'){
                                        GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[INSERT]-[SUCCESS]', '');
                                        Conexao.post('chamadosController/insertNovoChamadoTecnico', {
                                            ...parametros, 
                                            importada :  't',
                                            empresa : equipamento.CMQ00001_EMPRESA, 
                                            destino :emailNotificacao,
                                            copia : copiaEmail, 
                                            assunto : 'Confirmação de Abertura de Ordem de Serviço',
                                            mensagem : equipamento,                                             
                                            observacoes : textoObservacoes,                                   
                                            contadorMono : contadorMono ? contadorMono : 0, 
                                            contadorColor : contadorColor ? contadorColor : 0, 
                                            situacao : situacaoEquipamento,                                                                   
                                        }).then(response=>{
                                            GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[TB02115]-[INSERT]-[SUCCESS]', '');
                                        }).catch(error=>{
                                            GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[TB02115]-[INSERT]-[CATCH]-[467]', error);
                                            GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[defeito]-['+contadorMono+']-['+contadorColor+']-['+contadorTotal+']', textoObservacoes)
                                        })
                                        Swal.fire({
                                            title : 'Corporate Web', 
                                            text : 'Ordem de Serviço aberta com sucesso!', 
                                            icon : 'success', 
                                            showConfirmButton : false, 
                                            showCancelButton : false, 
                                            timer : 2000
                                        })
                                        GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[REDIRECT]-[SOLICITACOES-CHAMADO]', '');
                                        /*props.history.push({
                                            pathname : '/solicitacoes-chamado/empresa', state:{
                                              ...props.state,
                                              filtrar : true, 
                                              numeroSerie : equipamento.TB02112_NUMSERIE
                                            }
                                          })*/


                                          setOpen(false)  
                                      
                                    }else{
                                        GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[INSERT]-[FALHA]-[487]', response.data.status.message ? response.data.status.message : '');
                                        Conexao.post('chamadosController/insertNovoChamadoTecnico', {
                                            ...parametros, 
                                            importada :  'f',
                                            empresa : equipamento.CMQ00001_EMPRESA, 
                                            destino :emailNotificacao,
                                            copia : copiaEmail, 
                                            assunto : 'Solicitação de Abertura de Ordem de Serviço',
                                            mensagem : equipamento, 
                                            solicitante : solicitante, 
                                            observacoes : textoObservacoes,                                   
                                            contadorMono : contadorMono ? contadorMono : 0, 
                                            contadorColor : contadorColor ? contadorColor : 0, 
                                            situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A',                                   
                                
                                        }).then(response=>{
                                            GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[TB02115]-[INSERT]-[SUCCESS]', '');
                                        }).catch(error=>{
                                            GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[TB02115]-[INSERT]-[CATCH]-[505]', error);
                                        })
                                        Swal.fire({
                                            title : 'Corporate Web', 
                                            text : 'Ordem de Serviço aberta com sucesso!', 
                                            icon : 'success', 
                                            showConfirmButton : false, 
                                            showCancelButton : false, 
                                            timer : 2000
                                        })
                                    }
                                    
                                    setOpen(false)

                                }).catch(error=>{

                                    GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[DATASERVICE]-[INSERT]-[CATCH]-[518]', error);

                                    Conexao.post('chamadosController/insertNovoChamadoTecnico', {
                                        ...parametros, 
                                        importada :  'f',
                                        empresa : equipamento.CMQ00001_EMPRESA, 
                                        destino :emailNotificacao,
                                        copia : copiaEmail, 
                                        assunto : 'Solicitação de Abertura de Ordem de Serviço',
                                        mensagem : equipamento, 
                                        solicitante : solicitante, 
                                        observacoes : textoObservacoes,                                   
                                        contadorMono : contadorMono ? contadorMono : 0, 
                                        contadorColor : contadorColor ? contadorColor : 0, 
                                        situacao : equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A',                                   
                            
                                    }).then(response=>{

                                        GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[TB02115]-[INSERT-REDUNDANCIA]-[SUCCESS]', '');

                                    }).catch(error=>{

                                        GeradorLogErro(props.dadosEmpresa, '[MODAL CHAMADOS]-['+equipamento.TB02112_NUMSERIE+']-[TB02115]-[INSERT]-[CATCH]-[536]', error);

                                    })

                                    Swal.fire({
                                            title : 'Corporate Web', 
                                            text : 'Ordem de Serviço aberta com sucesso!', 
                                            icon : 'error', 
                                            showConfirmButton : false, 
                                            showCancelButton : false, 
                                            timer : 2000
                                        })
                                        GeradorLog(props.dadosEmpresa, '[MODAL CHAMADOS]-[FECHADO] ')
                                        setOpen(false)
                                })

                        }
                    })
                }}>Gravar</Button>
            </Grid>                 
            </Grid>
            
        </DialogContent>
       
      </Dialog>
    </div>
  );
}

export default withRouter(ModalAberturaChamado);
