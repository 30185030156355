import React , {useEffect, useState, useRef} from 'react';
import { Typography, Divider, TextField, Grid, InputAdornment, Button, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, LinearProgress} from '@material-ui/core';
import Swal from 'sweetalert2';
import Conexao from '../providers/Api/Api'
import {withRouter} from 'react-router-dom'
import { AlertTitle, Alert } from '@material-ui/lab';
import somenteTexto from '../providers/somenteTexto';
import Dataservice from '../providers/Api/Dataservice';

const SolicitacaoAtendimento = (props) => {
    const url = new URL(window.location.href);
    const {payload, coleta} =props.location.state
    const [contato, setContato] = useState('')
    const [emailContato, setEmailContato] = useState('')
    const [contadorMono, setContadorMono] = useState('0')
    const [contadorColor, setContadorColor] = useState('0')
    const [defeitoReclamado, setDefeitoReclamado]= useState('')
    const {equipamento}=props.location.state.payload

    useEffect(()=>{
        if(coleta.idcoleta){
            setContadorMono(coleta.monototal)
            setContadorColor(coleta.colortotal)
        }else{
            setContadorMono('0')
            setContadorColor('0')
        }
      }, [coleta])
 

    return <div style={{width :'100',  backgroundColor:'#f9f9f9', padding : '20px'}}>
            <div style ={{backgroundColor:"#444",padding :'5px', textAlign :"center" }}>
                <span style={{color:"#fff"}}>Solicitação de Suprimentos</span>
            </div>
            <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop :'10px'}}>
                
            <Grid container spacing={2} >
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>PAT</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02112_PAT : null}</strong></Typography>
                </Grid>            
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>SERIAL</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02112_NUMSERIE : null}</strong></Typography>
                </Grid>            
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>MODELO</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB01010_NOME : null}</strong></Typography>
                </Grid>            
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>Site</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02176_NOME : null}</strong></Typography>
                </Grid>            
            </Grid>
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Informação de contadores</Typography>
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Contador Monocromático'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        value={contadorMono}
                        onChange={(e)=>setContadorMono(e.target.value)}                        
                        variant='outlined'/>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Contador Color'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        value={contadorColor}
                        onChange={(e)=>setContadorColor(e.target.value)}
                        variant='outlined'/>
                </Grid>
            </Grid>
                {   

                coleta.dias > 0 
                ? 
                <Alert severity="error" style={{marginTop :'5px'}}>
                    <AlertTitle>Atenção</AlertTitle>
                    <Typography variant='caption'>Sua leitura é de uma data superior há 3 dias. verifique!</Typography>
                </Alert> 
                : 
                null

                }
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Informação do Solicitante</Typography>
           
            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Nome'
                        fullWidth
                        size='large'  
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={contato}
                        onChange={(e)=>setContato(e.target.value)}
                        />
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Email de contato'
                        fullWidth
                        size='large'  
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        value={emailContato}
                        onChange={(e)=>setEmailContato(e.target.value)}
                        />
                </Grid>
            </Grid>
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Typography variant='subtitle1'> Defeito</Typography>            
            <Grid container spacing={1} style={{marginTop : '10px'}}>
                <Grid item xs={12}>
                    <TextField 
                        label='Descreva o defeito apresentado'
                        fullWidth
                        size='large'
                        InputLabelProps={{shrink:true}}
                        variant='outlined'
                        multiline
                        rows={6} // Define o número de linhas visíveis                        
                        margin="normal"
                        value={defeitoReclamado}
                        placeholder='Descreva aqui o defeito apresentado pelo equipamento'
                        onChange={(e)=>setDefeitoReclamado(e.target.value)}
                        />
                </Grid>
               
            </Grid>
        </div>
        <div style ={{backgroundColor :'#fff', padding :'10px', borderRadius :'3px', boxShadow : '0px 0px 15px #d6d6d6', marginTop : '10px'}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Button className='btn btn-warning' variant='contained' color='secondary' fullWidth size='large' style={{height : '80px'}} onClick={()=>{
                            Swal.fire({
                                title :'Corporate WEB', 
                                text : 'Deseja cancelar o Chamado Técnico agora?', 
                                showConfirmButton:true, 
                                confirmButtonText:'Sim', 
                                showCancelButton:true, 
                                cancelButtonText : 'Não',
                            }).then(resp=>{
                                if(resp.isConfirmed){

                                    props.history.push({
                                        pathname : '/qrcode/'+props.match.params.produto+'/'+props.match.params.numeroserie+'/'+props.match.params.cliente, state:{
                                            payload : payload,  
                                            coleta : coleta  
                                                            
                                        }
                                      })

                                }

                            })

                        
                    }}>Cancelar</Button>    
                </Grid>
                <Grid item xs={6}>
                    <Button className='btn btn-warning' variant='contained' color='primary'fullWidth size='large' style={{height : '80px'}} onClick={()=>{
                            Swal.fire({
                                title :'Corporate WEB', 
                                text : 'Deseja confirmar a Abertura de Ordem de Serviço?', 
                                showConfirmButton:true, 
                                confirmButtonText:'Sim', 
                                showCancelButton:true, 
                                cancelButtonText : 'Não',
                            }).then(resp=>{
                                if(resp.isConfirmed){

                                    const currentDate = new Date();
                                    currentDate.setHours(currentDate.getHours() - 3);
                                    const isoStr = currentDate.toISOString();
                                  
                                    let contadorTotal = parseInt(contadorMono) + parseInt(contadorColor);

                                    const emailNotificacao = emailContato 
                                    const textoObservacoes = somenteTexto(defeitoReclamado);
                                    const parametros = {
                                            cadastro : isoStr, 
                                            operador : 'CORPORATE WEB',
                                            alteracao :  null, 
                                            opaltera : null,
                                            contrato : equipamento.TB02112_CODIGO, 
                                            codcli : equipamento.TB02111_CODCLI,
                                            produto : equipamento.TB02112_PRODUTO, 
                                            numserie : equipamento.TB02112_NUMSERIE, 
                                            data : isoStr, 
                                            atendente : 'CORPORATE WEB', 
                                            codemp : (equipamento.TB02176_CODEMP != null && equipamento.TB02176_CODEMP != '') ? equipamento.TB02176_CODEMP : equipamento.CMQ00001_CODEMPRESA, 
                                            situacao :  equipamento.CMQ00001_SITUACAO ? equipamento.CMQ00001_SITUACAO : 'A',  
                                            motivo : textoObservacoes ? textoObservacoes.substr(0,50) : '', 
                                            preventiva : 'N', 
                                            obs : textoObservacoes, 
                                            fecha : null, 
                                            reincidencia : 'N', 
                                            numos : null, 
                                            origem : 'C', 
                                            codtec : equipamento.CMQ00001_CODTEC ? equipamento.CMQ00001_CODTEC.padStart(4, 0) : '0000', 
                                            tipointerv : 'I', 
                                            statusos : '01', 
                                            ordem : 0, 
                                            contpb : contadorMono ? parseInt(contadorMono) : 0, 
                                            contcolor : contadorColor ? parseInt(contadorColor) : 0, 
                                            conttotal :contadorTotal ? parseInt(contadorTotal) : 0 ,
                                            logradouro:equipamento.TB02112_END, 
                                            num: equipamento.TB02112_NUM, 
                                            comp: (equipamento.TB02112_COMP && equipamento.TB02112_COMP != '') ? equipamento.TB02112_COMP.substr(0,20) : null, 
                                            bairro : equipamento.B02112_BAIRRO  ? equipamento.B02112_BAIRRO.substr(30) : null, 
                                            cidade : equipamento.TB02112_CIDADE , 
                                            estado : equipamento.TB02112_ESTADO , 
                                            cep : equipamento.TB02112_CEP, 
                                            fone : equipamento.B02112_FONE, 
                                            foneaux : equipamento.B02112_FONE, 
                                            local : equipamento.TB02112_LOCAL ? equipamento.TB02112_LOCAL.substr(0,200) : null, 
                                            site : equipamento.TB02112_CODSITE ? equipamento.TB02112_CODSITE : null,
                                            depto : equipamento.TB02112_CODDEPTO ? equipamento.TB02112_CODDEPTO  : null, 
                                            contato : somenteTexto(contato), 
                                            solicitante : somenteTexto(contato), 
                                            celular : '', 
                                            email : emailNotificacao ? emailNotificacao.substr(0, 200)  : ''                                 
                                            
                                        }
                                        if(parametros.codsite == '' ){
                                            parametros.codsite = null;  
                                        }
                                    
                                        if(parametros.coddepto == '' ){
                                            parametros.coddepto = null;  
                                        } 

                                        Dataservice.post('OsController/incluir', {
                                            ...parametros
                                        }).then(response=>{
                                            
                                            if(response.status == 200 && response.data.status.code == '1'){
                                      
                                                Swal.fire({
                                                    title: "Corporate", 
                                                    text: `Sua Ordem de Serviço foi gerada com sucesso com o número: ${response.data.value.codigo}`,
                                                    icon: 'success', 
                                                    showCancelButton: false, 
                                                    showConfirmButton: true, 
                                                  
                                                  });
                                                 
            
                                            }else{
                                               
                                                  Swal.fire({
                                                    title : "Corporate", 
                                                    text : "Não foi possível incluir",
                                                    icon : 'warning', 
                                                    showCancelButton : false, 
                                                    showConfirmButton : false, 
                                                    timer : 1500
                                                  })
                                                 
            
                                            }
            
                                            props.history.push({
                                                pathname : '/qrcode/'+props.match.params.produto+'/'+props.match.params.numeroserie+'/'+props.match.params.cliente, state:{
                                                    payload : payload,  
                                                    coleta : coleta                                                        
                                                }
                                            })

                                            
                                        }).catch(error=>{
                                            Swal.fire({
                                                title : "Corporate", 
                                                text : "Não foi possível incluir",
                                                icon : 'error', 
                                                showCancelButton : false, 
                                                showConfirmButton : false, 
                                                timer : 1500
                                              })
                                              
            
                                              props.history.push({
                                                pathname : '/qrcode/'+props.match.params.produto+'/'+props.match.params.numeroserie+'/'+props.match.params.cliente, state:{
                                                    payload : payload,  
                                                    coleta : coleta
                                                                    
                                                }
                                              })
                                        })


                                }
                            })

                        
                    }}>Confirmar</Button>    
                </Grid>
            </Grid>

        </div>
        <br/>
        <br/>
        <br/>
    </div>
}


export default (SolicitacaoAtendimento) 

