import React , {useEffect, useState, useRef} from 'react';
import { Typography, Divider, TextField, Grid, InputAdornment, Button, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell,Tooltip, LinearProgress} from '@material-ui/core';
import Swal from 'sweetalert2';
import Conexao from '../providers/Api/Api'
import Dataservice from '../providers/Api/Dataservice';
import PrinterconnectV2Api from '../providers/Api/PrinterconnectV2Api';
import IconeSolicitacoes from '@material-ui/icons/ShoppingCart';
import BuildIcon from '@material-ui/icons/Build';
import {withRouter} from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'


const  HomeQRCode = (props) =>{
    const url = new URL(window.location.href);
    const {payload} = props.location.state
    //const {dominio} = props.location.state
    const [equipamento, setEquipamento] = useState([]);
    const [contrato, setContrato] = useState([]);
    const [site, setSite] = useState([]);
    const [coleta, setColeta]= useState([]);
    const [loading, setLoading] = useState(false)
    const urlImagen = (url.host =='centermaq.net' || url.host == 'www.centermaq.net') ? 'http://centermaq.net/img/centermaq.png' : 'http://uziprint.com/img/uziprint.png'


   
  useEffect(()=>{

    getContadoresPrinterconnect()
    
  }, [])

  

  const getContadoresPrinterconnect = () =>{
    const dominio = 'centermaq'
    setLoading(true)
    PrinterconnectV2Api.post('coletasController/getLeitura',{
        numeroserie : payload.equipamento.TB02112_NUMSERIE , 
        idempresa : (dominio == 'centermaq' || dominio =="centermaq.net" || dominio == "www.centermaq.net") ? 2 : 3, 

    }).then(response => {
        if(response.status == 200 && response.data.status == 'OK'){
            setColeta(response.data.payload)
           
        }else{
            setColeta([])
        }
        setLoading(false)
    }).catch(error => {
        setColeta([])
        setLoading(false)
    })
  }

    

    

    return <div className='main-container-chamadoQR'>
        
        <div className='container-chamado-qrcode'>
                    
                    <div id='banner-qrcode' style={{paddingTop :'30px', paddingBottom : '30px'}}>
                        <img src ={urlImagen} width='70%'/>
                    </div>
                    <div className='container-chamado-qrcode-overlay'>
            <Grid container spacing={2} >
               
                <Grid item xs={3}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>PAT</Typography>
                    <Typography variant='subtitle2' style={{fontSize :'4vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02112_PAT : null}</strong></Typography>
                </Grid>  
                <Grid item xs={9}>
                    <Typography variant='subtitle2' style={{fontSize :'3vw'}}>SERIAL</Typography>
                    <Typography variant='subtitle2' style={{fontSize :'4vw'}}><strong>{payload.equipamento ? payload.equipamento.TB02112_NUMSERIE : null}</strong></Typography>
                </Grid>              
            </Grid>

            <Grid container spacing={2}>
                
                <Grid item xs={12}>
                <Typography variant='subtitle2' style={{fontSize :'3vw'}}>MODELO</Typography>
                    <Typography variant='subtitle2' style={{fontSize :'4vw'}} className='texto-limitado'><strong>{payload.equipamento ? payload.equipamento.TB01010_NOME : null}</strong></Typography>
                </Grid>            
            </Grid>

            <Grid container spacing={2}>
                
                <Grid item xs={10}>
                <Typography variant='subtitle2' style={{fontSize :'3vw'}}>SITE</Typography>
                    <Typography variant='subtitle2' style={{fontSize :'4vw'}} className='texto-limitado'><strong>{payload.equipamento ? payload.equipamento.TB02176_NOME : null}</strong></Typography>
                </Grid>            
            </Grid>
            <Grid container spacing={2}>
                
                <Grid item xs={10}>
                <Typography variant='subtitle2' style={{fontSize :'3vw'}}>DEPARTAMENTO</Typography>
                    <Typography variant='subtitle2' style={{fontSize :'4vw'}} className='texto-limitado'><strong>{payload.equipamento ? payload.equipamento.TB02177_NOME : "* NÃO CADASTRADO"}</strong></Typography>
                </Grid>            
            </Grid>
            
            { loading ? <LinearProgress variant='indeterminate' color='secondary' style={{marginTop : '25px'}}/> : <Divider style={{marginTop : '25px'}}/> }
        
        
        <Grid container spacing={1} style={{marginTop:'18px',}}>
            <Button variant='outlined' style={{backgroundColor:'#fff', paddingTop:'10px', paddingBottom :'10px' ,backgroundColor :'#f5f5f5', border : '0px', }}fullWidth onClick={()=>{
                props.history.push({
                    pathname : '/suprimento/'+payload.equipamento.TB02112_PRODUTO+'/'+payload.equipamento.TB02112_NUMSERIE+"/"+props.match.params.cliente, state : {
                        payload : payload, 
                        coleta : coleta
                    }
                })
                    
            }}> 
                <div>
                    <IconeSolicitacoes style={{fontSize:'10vw',color:'#444'}} /><br/>
                    <Typography variant='caption'>Solicitação de Toner</Typography>
                </div>
                
            </Button>
        </Grid>
        <Grid container spacing={1} style={{marginTop:'18px'}}>
            <Button variant='outlined' style={{ backgroundColor:'#fff', paddingTop:'10px', paddingBottom :'10px' ,backgroundColor :'#f5f5f5'}} fullWidth onClick={()=>{
                props.history.push({
                    pathname : '/atendimento/'+payload.equipamento.TB02112_PRODUTO+'/'+payload.equipamento.TB02112_NUMSERIE+"/"+props.match.params.cliente, state : {
                        payload : payload, 
                        coleta : coleta
                    }
                })
                    
            }}>
                <div>
                    <BuildIcon  style={{fontSize:'10vw',color:'#444'}}/><br/>
                    <Typography variant='caption'>CHAMADO TÉCNICO</Typography>  
                </div>
                   
            </Button>
        </Grid>
        <Grid container spacing={1} style={{marginTop:'18px'}}>
            <Button variant='outlined' style={{ backgroundColor:'#fff', paddingTop:'10px', paddingBottom :'10px' ,backgroundColor :'#f5f5f5'}} fullWidth color='secondary' onClick={()=>{
                Swal.fire({
                    title :'Corporate WEB', 
                    text : 'Deseja sair?', 
                    showConfirmButton:true, 
                    confirmButtonText:'Sim', 
                    showCancelButton:true, 
                    cancelButtonText : 'Não',
                    icon:'warning'
                }).then(resp=>{
                    if(resp.isConfirmed){
                        props.history.push({
                            pathname : '/qrcode/'+payload.equipamento.TB02112_PRODUTO+'/'+payload.equipamento.TB02112_NUMSERIE
                        })
                    }
                })
                    
            }}>
                <div>
                    <CloseIcon style={{fontSize:'10vw', }} /><br/>
                    <Typography variant='caption'>SAIR</Typography> 
                </div>
                    
            </Button>
        </Grid>
        </div>
        </div>
    </div>
}

export default withRouter(HomeQRCode)